<template>
  <div>
    <h1 class="headline--md text-bold text-uppercase">User Transfer Transaction Detail</h1>

    <div v-if="swapToko" class="box box--md box--white mt-16">
      <table class="table table--list three-items">
        <tbody>
          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Email</p>
                <h2 class="headline--md mt-4">{{ swapToko.user.email }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Full Name</p>
                <h2 class="headline--md mt-4">
                  {{ swapToko.user && swapToko.user.fullName ? swapToko.user.fullName : '' }}
                </h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Total sent amount</p>
                <h2 class="headline--md mt-4 text-success">{{ swapToko.amount | number_with_commas }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Transaction hash</p>
                <router-link
                  class="headline--md mt-4 link--primary link--underline"
                  :to="`/etherscan/${swapToko.tnxHash}`"
                  >{{ swapToko.tnxHash }}</router-link
                >
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Wallet address</p>
                <h2 class="headline--md mt-4">{{ swapToko.userWalletAddress }}</h2>
              </div>
            </td>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Transaction Time</p>
                <h2 class="headline--md mt-4">{{ swapToko.userTransferAtMilliseconds | moment_lts }}</h2>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="statistic">
                <p class="statistic__title text-warm">Status</p>
                <h2 class="headline--md mt-4" v-html="formatSwapTokoStatus(swapToko.status)" />
              </div>
            </td>
            <td v-if="swapToko.user.blacklist">
              <p class="statistic__title text-warm">Black List</p>
              <h2 class="headline--md mt-4 text-bold text-error">
                {{ swapToko.user.blacklist | format_blacklist_type }}
              </h2>
            </td>
            <td v-else />
            <td />
          </tr>

          <tr
            v-if="
              (enableRejectAction || enableApproveAction) &&
              currentUser &&
              $auth('Page', currentUser, $accessRule.SwapTokoUpdate).canView()
            "
          >
            <td class="has-border" colspan="3">
              <div class="d-flex">
                <div>
                  <p v-if="enableApproveAction" class="headline">
                    Would you like to send <span class="text-bold">{{ swapToko.amount }}</span> TOKO Ver. 2 to this
                    wallet address <br />
                    after finishing the review?
                  </p>
                  <p v-if="isBlacklist" class="headline"><toko-warning-icon /> This is blacklist account !</p>
                </div>

                <div class="text-center">
                  <button v-if="enableRejectAction" class="btn btn--red-bordered" @click="onOpenRejectConfirmDialog">
                    Reject
                  </button>
                  <button v-if="enableApproveAction" class="btn btn--primary" @click="onOpenApproveConfirmDialog">
                    Send
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="adminReview && (isRejected || isApproved)">
      <h2 class="headline--md text-bold text-uppercase mt-24">Admin review detail</h2>
      <div class="box box--md box--white mt-16">
        <table class="table table--list three-items">
          <tbody>
            <tr>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Full Name</p>
                  <h2 class="headline--md mt-4">{{ adminReview.fullName ? adminReview.fullName : '' }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Email</p>
                  <h2 class="headline--md mt-4">{{ adminReview.email }}</h2>
                </div>
              </td>
              <td>
                <div class="statistic">
                  <p class="statistic__title text-warm">Review at</p>
                  <h2 class="headline--md mt-4">{{ swapToko.adminHandleAt | moment_lts }}</h2>
                </div>
              </td>
            </tr>

            <tr>
              <td>
                <div class="statistic">
                  <p v-if="isRejected" class="statistic__title text-warm">Reject Note</p>
                  <p v-else class="statistic__title text-warm">Approved Note</p>
                  <h2 class="headline--md mt-4">{{ swapToko.adminNote }}</h2>
                </div>
              </td>
              <td v-if="isApproved">
                <div class="statistic">
                  <p class="statistic__title text-warm">Tnx. Hash</p>
                  <router-link
                    class="headline--md mt-4 link--primary link--underline"
                    :to="`/etherscan/${swapToko.tnxHash}`"
                    >{{ swapToko.tnxHash }}</router-link
                  >
                </div>
              </td>
              <td v-else />
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <p v-if="isApprovedFail" class="mt-8 text-italic text-error">Please contact admin to handle this case!</p>

    <div v-if="enableRejectAction || enableApproveAction">
      <confirm-dialog
        v-if="isConfirmRejectRequest"
        :open.sync="isConfirmRejectRequest"
        confirmText="Are you sure to reject this request ?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onRejectSwapToko"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="adminNote"
            placeholder="Reject note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>

      <confirm-dialog
        v-if="isConfirmApproveRequest"
        :open.sync="isConfirmApproveRequest"
        confirmText="Are you sure you want to send TOKO Ver. 2 to this account?"
        confirmButtonText="Continue"
        confirmButtonClass="text-purple"
        @confirm="onApproveSwapToko"
      >
        <div class="form-group mt-24">
          <textarea
            v-model="adminNote"
            placeholder="Approve note"
            rows="4"
            cols="50"
            type="textarea"
            class="form-control"
          />
        </div>
      </confirm-dialog>
    </div>

    <confirm-dialog
      v-if="isConfirmRetryRequest"
      :open.sync="isConfirmRetryRequest"
      confirmText="Are you sure you want to retry?"
      confirmButtonText="Continue"
      confirmButtonClass="text-purple"
      @confirm="onApproveSwapToko"
    />

    <loading :open="!isLoaded" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import formatSwapTokoStatus from '@/mixins/swap-toko-status'
import toggleLoading from '@/mixins/toggle-loading'
import { SWAP_TOKO_USER_TRANSFER_STATUS } from '@/utils/key-mapping'
const ConfirmDialog = () => import('@/components/ConfirmDialog')
const TokoWarningIcon = () => import('@/ui/toko-icons/TokoWarning')

export default {
  data() {
    return {
      requestStatus: SWAP_TOKO_USER_TRANSFER_STATUS,
      isConfirmRejectRequest: false,
      isConfirmApproveRequest: false,
      isConfirmRetryRequest: false,
      adminNote: ''
    }
  },

  mixins: [formatSwapTokoStatus, toggleLoading],

  computed: {
    ...mapState('swapToko', ['swapToko']),

    ...mapState('boUsers', ['boUsers']),

    enableRejectAction() {
      return (
        this.swapToko &&
        this.swapToko.status === this.requestStatus.userTransferSuccess &&
        (this.swapToko.status === this.requestStatus.userTransferSuccess || this.isBlacklist)
      )
    },

    enableApproveAction() {
      return this.swapToko && this.swapToko.status === this.requestStatus.userTransferSuccess && !this.isBlacklist
    },

    canRetry() {
      return this.swapToko.status === this.requestStatus.adminTransferFailed
    },

    isBlacklist() {
      return this.swapToko && this.swapToko.user.blacklist > 0
    },

    isRejected() {
      return this.swapToko && this.swapToko.status === this.requestStatus.adminReject
    },

    isApproved() {
      return this.swapToko && this.swapToko.status === this.requestStatus.adminTransferSuccess
    },

    isApprovedFail() {
      return this.swapToko && this.swapToko.status === this.requestStatus.adminTransferFailed
    },

    adminReview() {
      let admin
      if (this.swapToko && this.boUsers) {
        admin = this.boUsers.filter(item => item.id === this.swapToko.adminId)[0]
      }

      return admin
    }
  },

  components: {
    ConfirmDialog,
    TokoWarningIcon
  },

  methods: {
    ...mapActions('swapToko', ['getSwapToko', 'rejectSwapToko', 'approveSwapToko']),

    ...mapMutations('swapToko', ['CLEAR_SWAP_TOKO_DATA']),

    ...mapActions('boUsers', ['getBoUsers']),

    onOpenRejectConfirmDialog() {
      this.adminNote = ''
      this.isConfirmRejectRequest = true
    },

    onOpenApproveConfirmDialog() {
      this.adminNote = ''
      this.isConfirmApproveRequest = true
    },

    onCloseConfirmDialogs() {
      this.adminNote = ''
      this.isConfirmRejectRequest = false
      this.isConfirmApproveRequest = false
    },

    getReviewRequestParams() {
      return {
        id: parseInt(this.$route.params.id),
        adminNote: this.$sanitize(this.adminNote)
      }
    },

    onRejectSwapToko() {
      this.toggleLoading()
      this.rejectSwapToko(this.getReviewRequestParams())
        .then(() => {
          this.$router.push({ name: 'SwapUserTransfers' })
        })
        .finally(() => {
          this.toggleLoading()
          this.onCloseConfirmDialogs()
        })
    },

    onApproveSwapToko() {
      this.toggleLoading()
      this.approveSwapToko(this.getReviewRequestParams())
        .then(() => {
          this.$router.push({ name: 'SwapUserTransfers' })
        })
        .finally(() => {
          this.toggleLoading()
          this.onCloseConfirmDialogs()
        })
    }
  },

  async created() {
    this.CLEAR_SWAP_TOKO_DATA()
    try {
      await this.getBoUsers()
      await this.getSwapToko(this.$route.params.id)
    } catch (e) {
      this.toggleLoading()
      throw e
    }

    this.toggleLoading()
  }
}
</script>
